import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { DecoratedComment } from '@api/member.models';
import { useBiLogger } from '@common/providers';
import { ItemContainer, ItemHeader, ItemContent } from './elements';
import { getAvailableActions } from './CommentsListItem.utils';

interface CommentsListItemProps {
  isRTL?: boolean;
  isMobile?: boolean;
  data: DecoratedComment;
  handleOpenItem?: (comment: DecoratedComment) => void;
  handleDeleteItem?: (comment: DecoratedComment) => void;
  handleCopyItemLink?: (commentUrl: string) => void;
}

const CommentsListItem: FC<CommentsListItemProps> = ({
  isRTL,
  isMobile,
  data,
  handleOpenItem,
  handleDeleteItem,
  handleCopyItemLink,
}) => {
  const { t } = useTranslation();
  const { logEvent } = useBiLogger();

  const availableActions = getAvailableActions(
    data,
    {
      handleCopyItemLink,
      handleDeleteItem,
    },
    t,
  );

  const COMMON_BI_DATA = {
    type: 'comment',
    post_id: data.id,
    parent_post_id: data.post.id,
  };

  return (
    <ItemContainer isMobile={isMobile}>
      <ItemHeader isMobile={isMobile} data={data} />

      <ItemContent
        isRTL={isRTL}
        isMobile={isMobile}
        data={data}
        availableActions={availableActions}
        handleLogEvent={(biData: any) => {
          logEvent({
            ...COMMON_BI_DATA,
            ...biData,
          });
        }}
        handleOpenItem={() => {
          if (handleOpenItem) {
            logEvent({
              ...COMMON_BI_DATA,
              evid: 516,
            });
            handleOpenItem(data);
          }
        }}
      />
    </ItemContainer>
  );
};

export default CommentsListItem;
