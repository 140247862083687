import React, { FC } from 'react';
import { createPermissionCheckerClientAPI } from '@wix/communities-forum-client-commons';
import { getGuestPermissions } from '@wix/communities-forum-universal/dist/src/services/permissions/permissions';

import { CurrentUser } from '@common/services';
import { PermissionsContext } from './permissions.context';

interface PermissionsContextProviderProps {
  user?: CurrentUser;
}

export const PermissionsContextProvider: FC<PermissionsContextProviderProps> = ({
  user,
  children,
}) => {
  if (!user) {
    return null;
  }

  const permissions = createPermissionCheckerClientAPI({
    user,
    usersPermissions:
      user && user.userPermissions
        ? user.userPermissions
        : getGuestPermissions(),
    // @TODO ???
    getCategory: () => null,
  });

  return (
    <PermissionsContext.Provider value={permissions}>
      {children}
    </PermissionsContext.Provider>
  );
};
