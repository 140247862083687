import React from 'react';
import { TFunction } from '@wix/yoshi-flow-editor';
import DeleteIcon from 'wix-ui-icons-common/on-stage/Delete';
import LinkIcon from 'wix-ui-icons-common/on-stage/Link';

import { DecoratedComment } from '@api/member.models';

export const getAvailableActions = (
  data: DecoratedComment,
  {
    handleDeleteItem,
    handleCopyItemLink,
  }: {
    handleDeleteItem?: (data: DecoratedComment) => void;
    handleCopyItemLink?: (url: string) => void;
  },
  t: TFunction,
) => {
  const availableActions = [];

  if (handleCopyItemLink && data.url) {
    availableActions.push({
      biData: {
        action: 'copy-link',
        evid: 526,
      },
      label: t('share-link.copy'),
      icon: <LinkIcon />,
      handleOnClick: () => handleCopyItemLink(data.url),
    });
  }

  if (handleDeleteItem) {
    availableActions.push({
      biData: {
        action: 'delete-comment',
        evid: 526,
      },
      label: t('comment-actions.delete-comment'),
      icon: <DeleteIcon />,
      handleOnClick: () => handleDeleteItem(data),
    });
  }

  return availableActions;
};
